<template>
  <div class="modalMediaView">
    <Loader1A v-if="!loaded" class="mediaLoader" size="80px" />
    <div class="modalMediaViewPlayer" :style="style">
      <IntrinsicSVG width="720" height="1280" />
      <img alt="Image" :src="src" v-if="type === 'image'" />
      <video
        :src="src"
        v-if="type === 'video'"
        v-bind="video"
        @onplay="mediaLoaded"
        @play="mediaLoaded"
        @loadstart="mediaLoaded"
        controlsList="nodownload"
      />
      <!-- <div class="MediaControls">
        <a class="button" active="button" @click="modal.close(() => {})"><iconic name="times" /></a>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["modal"],
  data: function() {
    return {
      type: null,
      loaded: false,
      style: {
        opacity: 0,
      },
      video: {
        controls: true,
        autoplay: true,
        playsinline: true,
        autoplay: true,
      },
    };
  },
  methods: {
    loadImage: function() {
      const image = new Image();
      image.src = this.src;

      image.onload = () => {
        this.mediaLoaded();
      };

      image.onerror = () => {
        this.mediaLoaded();
      };
    },
    loadVideo: async function() {
      const video = await Go.awaitUntilElement(".modalMediaViewPlayer video");

      if (!video) {
        return;
      }

      video.oncanplay = () => {
        this.mediaLoaded();
      };

      video.onerror = () => {
        this.mediaLoaded();
      };
    },
    mediaLoaded: function() {
      this.loaded = true;
      this.style.opacity = 1;
    },
    initMedia: function() {
      this.type = this.mediaType(this.modal.media.type);

      if (this.type === "image") {
        return this.loadImage();
      }

      if (this.type === "video") {
        return this.loadVideo();
      }
    },
  },
  mounted: function() {
    this.initMedia();
  },
  computed: {
    src: function() {
      return this.modal.media.src || this.modal.media.location;
    },
  },
};
</script>

<style lang="scss">
.modalMediaView {
  position: relative;

  .mediaLoader {
    position: fixed;
  }

  .myLoader {
    @include Glass();
  }

  .modalMediaViewPlayer {
    @include animation(fadeIn);
    @include Glass(rgb(0, 0, 0), 0.8);
  }

  @include screen($tablet_width) {
    .modalMediaViewPlayer {
      @include Glass(rgb(0, 0, 0), 0);
    }
  }
}
</style>
